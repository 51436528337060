const TUIChat = {
  '【安全提示】本 APP 仅用于体验腾讯云即时通信 IM 产品功能，不可用于业务洽谈与拓展。请勿轻信汇款、中奖等涉及钱款的信息，勿轻易拨打陌生电话，谨防上当受骗。': '【安全提示】本 APP 僅用於體驗騰訊雲即時通信 IM 產品功能，不可用於業務洽談與拓展。請勿輕信匯款、中奖等涉及錢款的信息，勿輕易撥打陌生電話，謹防上當受騙。',
  '按Enter发送，Ctrl+Enter换行': '按Enter發送，Ctrl+Enter換行',
  '表情': '[表情]',
  '查看更多': '查看更多',
  '撤回': '撤回',
  '撤回了一条消息': '撤回了一條消息',
  '打开': '打開',
  '单击下载': '單擊下載',
  '点此投诉': '點此投訴',
  '点击处理': '點擊處理',
  '对方正在输入': '對方正在輸入...',
  '发起群视频': '發起群視頻',
  '发起群语音': '發起群語音',
  '发起通话': '發起通話',
  '发送失败': '發送失敗',
  '复制': '複製',
  '该消息不存在': '該消息不存在',
  '关闭阅读状态': '關閉',
  '管理员开启全员禁言': '管理員開啟全員禁言',
  '欢迎使用TUICallKit': '歡迎使用騰訊雲音視頻通話組件TUICallKit',
  '回到最新位置': '回到最新位置',
  '回复': '回覆',
  '回复详情': '回覆詳情',
  '集成TUICallKit': '集成 TUICallKit',
  '检测到您暂未集成TUICallKit，无法体验音视频通话功能': '檢測到您暫未集成TUICallKit，無法體驗音視頻通話功能。',
  '结束群聊': '結束群聊',
  '进行体验': '進行體驗。',
  '经度': '經度',
  '拒绝': '拒絕',
  '拒绝通话': '拒絕通話',
  '开通腾讯实时音视频服务': '開通騰訊實時音視頻服務',
  '聊天记录': '[聊天記錄]',
  '描述': '描述',
  '内容包含敏感词汇': '內容包含敏感詞彙',
  '您': '您',
  '您当前购买使用的套餐包暂未开通此功能': '您當前購買使用的套餐包暫未開通此功能。',
  '您当前购买使用的套餐包暂未开通群消息已读回执功能': '您當前購買使用的套餐包暫未開通【群消息已讀回執】功能，請升級至旗艦版進行體驗',
  '您当前购买使用的套餐包暂未开通在线用户列表功能': '您當前購買使用的套餐包暫未開通【在線用戶列表】功能，請升級至旗艦版進行體驗',
  '您已被管理员禁言': '您已被管理員禁言',
  '您有': '您有',
  '请点击': '請點擊',
  '请输入消息': '請輸入消息',
  '取消': '取消',
  '取消通话': '取消通話',
  '全部已读': '全部已讀',
  '确定': '確定',
  '确认重发该消息？': '確認重發該消息？',
  '人关闭阅读状态': '人關閉閱讀狀態',
  '人未读': '人未讀',
  '人已读': '人已讀',
  '删除': '刪除',
  '涉及敏感内容': '涉及敏感內容',
  '申请加入': '申請加入',
  '视频': '[視頻]',
  '视频通话': '視頻通話',
  '条回复': '條回覆',
  '条入群申请': '條入群申請',
  '条新消息': '條新消息',
  '通话时长': '通話時長',
  '同意': '同意',
  '图片': '[圖片]',
  '图片消息失败,无效的图片格式': '圖片消息失敗,無效的圖片格式',
  '纬度': '緯度',
  '未读': '未讀',
  '位置': '[位置]',
  '文件': '[文件]',
  '文件不存在,请检查文件路径是否正确': '文件不存在,請檢查文件路徑是否正確',
  '文件大小超出了限制,如果上传文件,最大限制是100MB': '文件大小超出了限制,如果上傳文件,最大限制是100MB',
  '文件消息失败,禁止发送违规封禁的文件': '文件消息失敗,禁止發送違規封禁的文件',
  '我': '我',
  '无法查看': '無法查看',
  '无法定位到原消息': '無法定位到原消息',
  '无法收听': '無法收聽',
  '正在加载': '正在加載...',
  '加载结束': '加載結束',
  '无应答': '無應答',
  '系统消息': '系統消息',
  '消息': 'message',
  '消息或者资料中文本存在敏感内容,发送失败': '消息或者資料中文本存在敏感內容,發送失敗',
  '消息详情': '消息詳情',
  '消息长度超出限制,消息长度不要超过12K': '消息長度超出限制,消息長度不要超過12K',
  '消息中图片存在敏感内容,发送失败': '消息中圖片存在敏感內容,發送失敗',
  '选择提醒的人': '選擇提醒的人',
  '已读': '已讀',
  '已过撤回时限': '已過撤回時限',
  '已接听': '已接聽',
  '已拒绝': '已拒絕',
  '已同意': '已同意',
  '引用': '引用',
  '引用失败': '引用失敗',
  '语音': '[語音]',
  '语音通话': '語音通話',
  '重新编辑': '重新編輯',
  '重新发送': '重新發送',
  '转发': '轉發',
  '翻译': '翻譯',
  '转文字': '轉文字',
  '隐藏': '隱藏',
  '转发给': '轉發給',
  '自定义': '[自定義消息]',
  '自定义消息': '自定義消息',
  '腾讯云 IM': '騰訊雲 IM',
  '空': '空',
  '文本包含本地审核拦截词': '文本包含本地審核攔截詞',
  '按住说话': '按住說話',
  '抬起发送': '抬起發送',
  '抬起取消': '抬起取消',
  '正在录音': '正在錄音',
  '继续上滑可取消': '繼續上滑可取消',
  '松开手指 取消发送': '松開手指 取消發送',
  '此机型暂不支持复制功能': '此機型暫不支持複製功能',
  '请开通翻译功能': '請開通翻譯功能',
  '请开通语音转文字功能': '請開通語音轉文字功能',
  '翻译中': '翻譯中',
  '转换中': '轉換中',
  '由IM提供翻译支持': '由IM提供翻譯支持',
  '所有人': '所有人',
  '已达到表情回应上限数量': '已達到表情回應上限數量',
  '等': '等',
  '人': '人',
  '返回': '返回',
  '关闭': '關閉',
  '多选': '多選',
  '合并转发': '合併轉發',
  '逐条转发': '逐條轉發',
  '未选择消息': '未選擇消息',
  '引用内容已撤回': '引用內容已撤回',
  '[草稿]': '[草稿]',
  '[消息]': '[消息]',
  '[文本]': '[文本]',
  '[自定义消息]': '[自定義消息]',
  '[图片]': '[圖片]',
  '[音频]': '[音頻]',
  '[语音]': '[語音]',
  '[视频]': '[視頻]',
  '[文件]': '[文件]',
  '[位置]': '[地理位置]',
  '[地理位置]': '[地理位置]',
  '[动画表情]': '[動畫表情]',
  '[群提示]': '[群提示]',
  '[群提示消息]': '[群提示消息]',
  '[聊天记录]': '[聊天記錄]',
  '群聊的聊天记录': '群聊的聊天記錄',
  '和': '和',
  '的聊天记录': '的聊天記錄',
  '请升级IMSDK到v2.10.1或更高版本查看此消息': '請升級IMSDK到v2.10.1或更高版本查看此消息',
  '您已被管理员移出群聊': '您已被管理員移出群聊',
  '该群聊已被解散': '該群聊已被解散',
  '您已退出该群聊': '您已退出該群聊',
  '您已被禁止聊天': '您已被禁止聊天',
};

export default TUIChat;
