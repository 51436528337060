var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.Overlay,{attrs:{"maskColor":'transparent'},on:{"onOverlayClick":_setup.closeReadReceiptPanel}},[_c('div',{class:{
      'read-receipt-panel': true,
      'read-receipt-panel-mobile': _setup.isMobile,
      'read-receipt-panel-uni': _setup.isUniFrameWork,
      'read-receipt-panel-close-mobile': _setup.isMobile && _setup.isPanelClose,
    }},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"header-text"},[_vm._v(" "+_vm._s(_setup.TUITranslateService.t("TUIChat.消息详情"))+" ")]),_c('div',{staticClass:"header-close-icon"},[_c(_setup.Icon,{attrs:{"size":"12px","hotAreaSize":"8","file":_setup.closeIcon},on:{"onClick":_setup.closeReadReceiptPanel}})],1)]),_c('div',{staticClass:"read-status-counter-container"},_vm._l((_setup.tabNameList),function(tabName){return _c('div',{key:tabName,class:{
          'read-status-counter': true,
          'active': tabName === _setup.currentTabName,
        },on:{"click":function($event){return _setup.toggleTabName(tabName)}}},[_c('div',{staticClass:"status-text"},[_vm._v(" "+_vm._s(_setup.tabInfo[tabName].tabName)+" ")]),_c('div',{staticClass:"status-count"},[_vm._v(" "+_vm._s(_setup.tabInfo[tabName].count === undefined ? "" : _setup.tabInfo[tabName].count)+" ")])])}),0),_c('div',{staticClass:"read-status-member-list"},[(_setup.tabInfo[_setup.currentTabName].count === 0 && _setup.isFirstLoadFinished)?_c('div',{staticClass:"empty-list-tip"},[_vm._v(" - "+_vm._s(_setup.TUITranslateService.t('TUIChat.空'))+" - ")]):(_setup.isFirstLoadFinished)?[(_setup.currentTabName === 'unread')?_vm._l((_setup.tabInfo[_setup.currentTabName].memberList),function(item){return _c('div',{key:item.userID,staticClass:"read-status-member-container"},[_c(_setup.Avatar,{staticClass:"read-status-avatar",attrs:{"useSkeletonAnimation":"","url":item.avatar || ''}}),_c('div',{staticClass:"username"},[_vm._v(" "+_vm._s(item.nick || item.userID)+" ")])],1)}):_vm._e(),(_setup.currentTabName === 'read')?_vm._l((_setup.tabInfo[_setup.currentTabName].memberList),function(item){return _c('div',{key:item.userID,staticClass:"read-status-member-container"},[_c(_setup.Avatar,{staticClass:"read-status-avatar",attrs:{"useSkeletonAnimation":"","url":item.avatar}}),_c('div',{staticClass:"username"},[_vm._v(" "+_vm._s(item.nick || item.userID)+" ")])],1)}):_vm._e()]:_vm._e(),(_setup.isFirstLoadFinished)?_c('div',{staticClass:"fetch-more-container"},[_c(_setup.FetchMore,{attrs:{"isFetching":_setup.isPullDownFetching,"isTerminateObserve":_setup.isStopFetchMore},on:{"onExposed":_setup.pullDownFetchMoreData}})],1):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }