var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"image-previewer",class:[_setup.isMobile && 'image-previewer-h5']},[_c('div',{ref:"image",staticClass:"image-wrapper",on:{"touchstart":function($event){$event.stopPropagation();return _setup.handleTouchStart.apply(null, arguments)},"touchmove":function($event){$event.stopPropagation();return _setup.handleTouchMove.apply(null, arguments)},"touchend":function($event){$event.stopPropagation();return _setup.handleTouchEnd.apply(null, arguments)},"touchcancel":function($event){$event.stopPropagation();return _setup.handleTouchCancel.apply(null, arguments)},"wheel":function($event){$event.stopPropagation();return _setup.handleWheel.apply(null, arguments)}}},[_c('ul',{ref:"ulRef",staticClass:"image-list",style:({
        width: `${_vm.imageList.length * 100}%`,
        transform: `translateX(-${
          (_setup.currentImageIndex * 100) / _vm.imageList.length
        }%)`,
        transition: '0.5s',
      })},_vm._l((_vm.imageList),function(item,index){return _c('li',{key:index,staticClass:"image-item"},[_c(_setup.ImageItem,{class:[_setup.isUniFrameWork ? 'image-item' : ''],attrs:{"zoom":_setup.zoom,"rotate":_setup.rotate,"src":_setup.getImageUrl(item),"messageItem":item}})],1)}),0)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_setup.isPC),expression:"isPC"}],staticClass:"icon icon-close",on:{"click":_setup.close}},[_c(_setup.Icon,{attrs:{"file":_setup.iconClose,"width":"16px","height":"16px"}})],1),(_setup.isPC && _setup.currentImageIndex > 0)?_c('div',{staticClass:"image-button image-button-left",on:{"click":_setup.goPrev}},[_c(_setup.Icon,{attrs:{"file":_setup.iconArrowLeft}})],1):_vm._e(),(_setup.isPC && _setup.currentImageIndex < _vm.imageList.length - 1)?_c('div',{staticClass:"image-button image-button-right",on:{"click":_setup.goNext}},[_c(_setup.Icon,{attrs:{"file":_setup.iconArrowLeft}})],1):_vm._e(),_c('div',{class:['actions-bar', _setup.isMobile && 'actions-bar-h5']},[(_setup.isPC)?_c('div',{staticClass:"icon-zoom-in",on:{"click":_setup.zoomIn}},[_c(_setup.Icon,{attrs:{"file":_setup.iconZoomIn,"width":"27px","height":"27px"}})],1):_vm._e(),(_setup.isPC)?_c('div',{staticClass:"icon-zoom-out",on:{"click":_setup.zoomOut}},[_c(_setup.Icon,{attrs:{"file":_setup.iconZoomOut,"width":"27px","height":"27px"}})],1):_vm._e(),(_setup.isPC)?_c('div',{staticClass:"icon-refresh-left",on:{"click":_setup.rotateLeft}},[_c(_setup.Icon,{attrs:{"file":_setup.iconRotateLeft,"width":"27px","height":"27px"}})],1):_vm._e(),(_setup.isPC)?_c('div',{staticClass:"icon-refresh-right",on:{"click":_setup.rotateRight}},[_c(_setup.Icon,{attrs:{"file":_setup.iconRotateRight,"width":"27px","height":"27px"}})],1):_vm._e(),_c('span',{staticClass:"image-counter"},[_vm._v(" "+_vm._s(_setup.currentImageIndex + 1)+" / "+_vm._s(_vm.imageList.length)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }