var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'message-input-toolbar',
    !_setup.isPC && 'message-input-toolbar-h5',
    _setup.isUniFrameWork && 'message-input-toolbar-uni',
  ]},[_c('div',{class:[
      'message-input-toolbar-list',
      !_setup.isPC && 'message-input-toolbar-h5-list',
      _setup.isUniFrameWork && 'message-input-toolbar-uni-list',
    ]},[(_setup.featureConfig.InputImage)?_c(_setup.ImageUpload,{attrs:{"imageSourceType":"album"}}):_vm._e()],1),_c(_setup.UserSelector,{ref:"userSelectorRef",attrs:{"type":_setup.selectorShowType,"currentConversation":_setup.currentConversation,"isGroup":_setup.isGroup},on:{"submit":_setup.onUserSelectorSubmit,"cancel":_setup.onUserSelectorCancel}}),(_setup.isH5)?_c('div',{ref:"h5Dialog",class:['message-input-toolbar-h5-dialog']}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }