import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { TUIComponents, TUIChatKit } from "./TUIKit";
import { TUITranslateService, TUIStore, StoreName } from "@tencentcloud/chat-uikit-engine";
import TUINotification from "./TUIKit/components/TUINotification/index";
import {
  Button,
  Form,
  FormItem,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Input,
  Checkbox,
} from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import { locales } from "./locales";

const SDKAppID = 1600057786; // Your SDKAppID
const secretKey = "20a34acda22135b0706ee2867e225cabbf1433b4ab8a82741100d48851e9699a"; // Your secretKey

TUIChatKit.components(TUIComponents, Vue);
TUIChatKit.init();

TUITranslateService.provideLanguages(locales);
TUITranslateService.useI18n();

Vue.config.productionTip = false;
Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Input);
Vue.use(Checkbox);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

/**
 * Init TUINotification configuration.
 */
TUINotification.setNotificationConfiguration({
  showPreviews: true,
  allowNotifications: true,
  notificationTitle: "客服消息通知",
  notificationIcon: "https://yiliaoyun.shudaixiongyiliao.com/miniApp/static/logo.png",
});

 

/**
 * Listen for new messages and use notification components.
 * This capability is only available in the web environmen.
 */
TUIStore.watch(StoreName.CHAT, {
  newMessageList: (newMessageList: unknown) => {
    if (Array.isArray(newMessageList)) {
      newMessageList.forEach(message =>{
        
        // console.log("**********************8",message)
        window.parent.postMessage(
          {
            code: "message",
            text:  JSON.stringify(message),
          },
          "*"
        );
        
        
        // TUINotification.notify(message)
        });
    }
  }
});

export { SDKAppID, secretKey };
