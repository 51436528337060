var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tui-chat",class:[_setup.isH5 ? 'tui-chat-h5' : '']},[_c('div',{staticClass:"tui-chat-main",attrs:{"id":"tui-chat-main"},on:{"click":_setup.closeChatPop}},[(_setup.isOfficial)?_c('div',{staticClass:"tui-chat-safe-tips"},[_c('span',[_vm._v(" "+_vm._s(_setup.TUITranslateService.t( "TUIChat.【安全提示】本 APP 仅用于体验腾讯云即时通信 IM 产品功能，不可用于业务洽谈与拓展。请勿轻信汇款、中奖等涉及钱款的信息，勿轻易拨打陌生电话，谨防上当受骗。" ))+" ")]),_c('a',{on:{"click":function($event){return _setup.openComplaintLink(_setup.Link.complaint)}}},[_vm._v(" "+_vm._s(_setup.TUITranslateService.t("TUIChat.点此投诉"))+" ")])]):_vm._e(),(_vm.isGroup)?_c(_setup.MessageGroupApplication,{key:_setup.props.groupID,attrs:{"groupID":_setup.props.groupID}}):_vm._e(),_c('ul',{ref:"messageListRef",staticClass:"tui-message-list",attrs:{"id":"messageScrollList"},on:{"click":_setup.onMessageListBackgroundClick}},[(!_setup.isCompleted)?_c('p',{staticClass:"message-more",on:{"click":_setup.getHistoryMessageList}},[_vm._v(" "+_vm._s(_setup.TUITranslateService.t("TUIChat.查看更多"))+" ")]):_vm._e(),_vm._l((_setup.messageList),function(item,index){return _c('li',{key:item.ID,ref:"messageElementListRef",refInFor:true,staticClass:"message-li",attrs:{"id":'tui-' + item.ID}},[_c(_setup.MessageTimestamp,{attrs:{"currTime":item.time,"prevTime":index > 0 ? _setup.messageList[index - 1].time : 0}}),_c('div',{staticClass:"message-item"},[(
              item.type === _setup.TYPES.MSG_GRP_TIP ||
              _setup.isCreateGroupCustomMessage(item)
            )?_c(_setup.MessageTip,{attrs:{"content":item.getMessageContent(),"blinkMessageIDList":_setup.blinkMessageIDList},on:{"blinkMessage":_setup.blinkMessage}}):(item.isRevoked)?_c(_setup.MessageRevoked,{attrs:{"isEdit":item.type === _setup.TYPES.MSG_TEXT,"messageItem":_setup.shallowCopyMessage(item)},on:{"messageEdit":function($event){return _setup.handleEdit(item)}}}):(_setup.isPluginMessage(item))?_c(_setup.MessagePlugin,{attrs:{"message":_setup.deepCopy(item),"blinkMessageIDList":_setup.blinkMessageIDList},on:{"resendMessage":_setup.resendMessage,"handleToggleMessageItem":_setup.handleToggleMessageItem,"handleH5LongPress":_setup.handleH5LongPress}}):_c('div',{class:{
              'message-event-bind-div': true,
            },on:{"longpress":function($event){return _setup.handleToggleMessageItem($event, item, true)},"contextmenu":function($event){$event.preventDefault();return _setup.handleToggleMessageItemForPC($event, item)},"touchstart":function($event){return _setup.handleH5LongPress($event, item, 'touchstart')},"touchend":function($event){return _setup.handleH5LongPress($event, item, 'touchend')},"mouseover":function($event){return _setup.handleH5LongPress($event, item, 'touchend')}}},[_c(_setup.MessageBubble,{attrs:{"content":item.getMessageContent(),"isAudioPlayed":Boolean(_setup.audioPlayedMapping[item.ID]),"blinkMessageIDList":_setup.blinkMessageIDList,"isMultipleSelectMode":_vm.isMultipleSelectMode,"messageItem":JSON.parse(JSON.stringify(item)),"multipleSelectedMessageIDList":_setup.multipleSelectedMessageIDList},on:{"blinkMessage":_setup.blinkMessage,"resendMessage":function($event){return _setup.resendMessage(item)},"changeSelectMessageIDList":_setup.changeSelectMessageIDList,"setReadReceiptPanelVisible":_setup.setReadReceiptPanelVisible},scopedSlots:_vm._u([{key:"messageElement",fn:function(){return [(item.type === _setup.TYPES.MSG_TEXT)?_c(_setup.MessageText,{attrs:{"content":item.getMessageContent()}}):(
                    item.type === _setup.TYPES.MSG_IMAGE ||
                    (item.type == 'TIMCustomElem' &&
                      item.payload.data == 'image')
                  )?_c(_setup.ProgressMessage,{attrs:{"content":item.getMessageContent(),"messageItem":item}},[_c(_setup.MessageImage,{attrs:{"content":item.getMessageContent(),"messageItem":item},on:{"previewImage":_setup.handleImagePreview}})],1):(item.type === _setup.TYPES.MSG_VIDEO)?_c(_setup.ProgressMessage,{attrs:{"content":item.getMessageContent(),"messageItem":item}},[_c(_setup.MessageVideo,{attrs:{"content":item.getMessageContent(),"messageItem":item}})],1):(item.type === _setup.TYPES.MSG_AUDIO)?_c(_setup.MessageAudio,{attrs:{"content":item.getMessageContent(),"messageItem":item},on:{"setAudioPlayed":_setup.setAudioPlayed}}):(item.type === _setup.TYPES.MSG_FILE)?_c(_setup.ProgressMessage,{attrs:{"content":item.getMessageContent(),"messageItem":item}},[_c(_setup.MessageFile,{attrs:{"content":item.getMessageContent(),"messageItem":item}})],1):(item.type === _setup.TYPES.MSG_MERGER)?_c(_setup.MessageRecord,{attrs:{"renderData":item.payload,"messageItem":item}}):(item.type === _setup.TYPES.MSG_FACE)?_c(_setup.MessageFace,{attrs:{"content":item.getMessageContent()}}):(item.type === _setup.TYPES.MSG_LOCATION)?_c(_setup.MessageLocation,{attrs:{"content":item.getMessageContent()}}):(item.type === _setup.TYPES.MSG_CUSTOM)?_c(_setup.MessageCustom,{attrs:{"content":item.getMessageContent(),"messageItem":item}}):_vm._e()]},proxy:true},{key:"TUIEmojiPlugin",fn:function(){return [(_setup.isShowEmojiPlugin && item.reactionList.length > 0)?_c(_setup.TUIEmojiPlugin,{attrs:{"type":"reaction-detail","emojiConfig":_setup.emojiConfig,"message":_setup.shallowCopyMessage(item)}}):_vm._e()]},proxy:true}],null,true)})],1),(item.ID === _setup.toggleID)?_c(_setup.MessageTool,{ref:"messageToolListRef",refInFor:true,class:{
              'message-tool': true,
              'message-tool-out': item.flow === 'out',
              'message-tool-in': item.flow === 'in',
              'message-tool-bottom': _setup.isTopMessageDom,
            },attrs:{"messageItem":item,"isMultipleSelectMode":_vm.isMultipleSelectMode},on:{"toggleMultipleSelectMode":() => _setup.emits('toggleMultipleSelectMode')},scopedSlots:_vm._u([{key:"TUIEmojiPlugin",fn:function(){return [(_setup.isShowEmojiPlugin)?_c(_setup.TUIEmojiPlugin,{attrs:{"message":item,"emojiConfig":_setup.emojiConfig}}):_vm._e()]},proxy:true}],null,true)}):_vm._e()],1)],1)})],2),_c(_setup.ScrollButton,{ref:"scrollButtonInstanceRef",on:{"scrollToLatestMessage":_setup.scrollToLatestMessage}}),(_setup.reSendDialogShow)?_c(_setup.Dialog,{staticClass:"resend-dialog",attrs:{"show":_setup.reSendDialogShow,"isH5":!_setup.isPC,"center":true,"isHeaderShow":_setup.isPC},on:{"submit":function($event){return _setup.resendMessageConfirm()},"update:show":(e) => (_setup.reSendDialogShow = e)}},[_c('p',{staticClass:"delDialog-title"},[_vm._v(" "+_vm._s(_setup.TUITranslateService.t("TUIChat.确认重发该消息？"))+" ")])]):_vm._e(),(_setup.showImagePreview)?_c(_setup.ImagePreviewer,{attrs:{"currentImage":_setup.currentImagePreview,"imageList":_setup.imageMessageList},on:{"close":_setup.onImagePreviewerClose}}):_vm._e(),(_setup.isShowReadUserStatusPanel)?_c(_setup.ReadReceiptPanel,{attrs:{"message":Object.assign({}, _setup.readStatusMessage)},on:{"setReadReceiptPanelVisible":_setup.setReadReceiptPanelVisible}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }