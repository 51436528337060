import Login from './login.json';
import Home from './home.json';
import Profile from './profile.json';

const messages = {
  zh_tw: {
    当前语言: '繁體中文',
    即时通信: '即時通信',
    即时通信IM: '即時通信IM',
    社交娱乐: '社交娛樂',
    腾讯云: '騰訊雲',
    使用指引: '使用指引',
    Login,
    Home,
    Profile,
  },
};

export default messages;
